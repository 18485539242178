import React from 'react'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'

import kim_vertebrae from '../assets/images/kim_vertebrae.webp'
import publications from '../assets/images/publications.webp'
import conferences from '../assets/images/conferences.webp'
import media from '../assets/images/media.webp'
import podcasts from '../assets/images/sotsa.webp'
import evolving_health from '../assets/images/evolving_health.webp'
import affiliations from '../assets/images/affiliations.webp'
import students from '../assets/images/kim_teaching.webp'

class HomeIndex extends React.Component {
    render() {

        return (
            <Layout>
                <Helmet
                    title="Kimberly Plomp, PhD"
                    htmlAttributes={{lang: 'en'}}
                    meta={[
                        { name: 'description', content: 'Bioarchaeologist specializing in palaeopathology, evolutionary medicine, and skeletal variation.' },
                        { name: 'keywords', content: 'academic,anthropology,bioarchaeology,palaeopathology,biological,evolutionary,medicine' },
                    ]}
                >
                    <html lang="en" />
                </Helmet>

                <Banner />

                <div id="main">
                    <section id="one" className="tiles">
                        <article style={{backgroundImage: `url(${kim_vertebrae})`}}>
                            <header className="major">
                                <h3>Research Profile</h3>
                                <p>Education, work experience, and current positions</p>
                            </header>
                            <Link to="/profile" aria-label="Research Profile" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${publications})`}}>
                            <header className="major">
                                <h3>Publications</h3>
                                <p>Scientific journal articles and book chapters</p>
                            </header>
                            <Link to="/publications" aria-label="Publications" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${conferences})`}}>
                            <header className="major">
                                <h3>Conferences</h3>
                                <p>Past and upcoming presentations</p>
                            </header>
                            <Link to="/conferences" aria-label="Conferences" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${media})`}}>
                            <header className="major">
                                <h3>Science Communication</h3>
                                <p>Media coverage and interviews</p>
                            </header>
                            <Link to="/communications" aria-label="Science Communication" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${affiliations})`}}>
                            <header className="major">
                                <h3>Affiliations &amp; Collaborations</h3>
                                <p>Where I am and who I’m working with</p>
                            </header>
                            <Link to="/affiliations" aria-label="Affiliations and Collaborations" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${students})`}}>
                            <header className="major">
                                <h3>Student Projects</h3>
                                <p>What my awesome students are up to</p>
                            </header>
                            <Link to="/students" aria-label="Student Projects" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${podcasts})`}}>
                            <header className="major">
                                <h3>Podcasts</h3>
                                <p>Podcast hosting and episode participation</p>
                            </header>
                            <Link to="/podcasts" aria-label="Podcasts" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${evolving_health})`}}>
                            <header className="major">
                                <h3>Evolving Health</h3>
                                <p>Integrating palaeopathology and evolutionary medicine</p>
                            </header>
                            <Link to="/evolvinghealth" aria-label="Evolving Health" className="link primary"></Link>
                        </article>
                    </section>
                    <section id="two">
                        <div className="inner">
                            <header className="major">
                                <h2>Contact</h2>
                            </header>
                            <p>To reach out for more information</p>
                            <ul className="actions">
                                <li><Link to="/contact" className="button next">Contact</Link></li>
                            </ul>
                        </div>
                    </section>
                </div>

            </Layout>
        )
    }
}

export default HomeIndex
